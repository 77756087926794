/*
Refer using hooks solution from - 
https://medium.com/trabe/prevent-click-events-on-double-click-with-react-with-and-without-hooks-6bf3697abc40

to prevent click vents on double click
*/
import {useRef} from 'react';

const UseCancellablePromises = () => {
    const pendingPromises = useRef([]);

    const appendPendingPromises = promise => pendingPromises.current = [...pendingPromises.current, promise];

    const removePendingPromises = promise => pendingPromises.current = pendingPromises.current.filter(p => p !== promise);

    const clearPendingPromises = () => pendingPromises.current.map(p => p.cancel());

    const api = {
        appendPendingPromises,
        removePendingPromises,
        clearPendingPromises,
    }

    return api;
}

export default UseCancellablePromises;