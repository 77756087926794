import React from 'react'
import {
    PersonAdd,
    ExitToApp,
    ListAlt,
    Home,
    MonetizationOn,
    TrendingUp
} from '@material-ui/icons'
import ApplicationsList from "./../features/Application/ApplicationsList";
import NewApplication from "./../features/Application/NewApplication/NewApplication";
import CommissionsList from "./../features/Commission/CommissionsList";
import Performance from "./../features/Performance/Performance";

const routes = [
    {
        linkName: 'Home',
        path: '/',
        component: <NewApplication />,
        showInNavigation: false,
        icon: <Home />
    },
    {
        linkName: 'New Application',
        path: '/new',
        component: <NewApplication />,
        showInNavigation: true,
        icon: <PersonAdd />
    },
    {
        linkName: 'Applications',
        path: '/applications',
        component: <ApplicationsList />,
        showInNavigation: true,
        icon: <ListAlt />
    },
    {
        linkName: 'Commissions',
        path: '/commissions',
        component: <CommissionsList />,
        showInNavigation: true,
        icon: <MonetizationOn />
    },
    {
        linkName: 'Performance',
        path: '/performance',
        component: <Performance />,
        showInNavigation: true,
        icon: <TrendingUp />
    },
    {
        linkName: 'Signout',
        showInNavigation: true,
        icon: <ExitToApp />
    }
]

export default routes