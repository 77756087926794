import {useState, useEffect} from 'react'
import {
    Grid,
    Typography
} from '@material-ui/core';
import TargetComparison from "./TargetComparison";
import ProductsSold from "./ProductsSold";
import {getAgentPerformance} from "../../apiCalls/getAgentPerformance";
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import moment from 'moment';
import {connect} from 'react-redux';
import AgentSmartDropDown from "../common/AgentSmartDropDown/AgentSmartDropDown";

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const DEFAULT_DATA = {
    barChart: [
        {key: 'Actual', value: 0},
        {key: 'Target', value: 0}
    ],
    pieChart: [
        {value: 0, label: 'None'}
    ]
}

const Performance = props => {

    const {accountInfo} = props;

    const [data, setData] = useState({...DEFAULT_DATA});
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");
    const [agentCode, setAgentCode] = useState("");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(authToken) {
            getAgentPerformance(source, {
                year: moment().format('YYYY'),
                month: moment().format('M'),
                agentCode: agentCode
            }, authToken)
            .then(response => {
                setData({
                    barChart: [
                        {key: 'Actual', value: response.actual || 0},
                        {key: 'Target', value: response.salesTarget || 0}
                    ],
                    pieChart: [...response.distribution]
                })
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled fetching performance");
                } else {
                    console.log('Error fetching agent performance', error);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch agent performance, please try again"
                    });
                }
                setData({...DEFAULT_DATA});
            })
        }

        return () => source.cancel();
    }, [authToken, agentCode]);

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <Grid item xs={6}>
                    <Typography variant="h5">Performance Of </Typography>
                </Grid>
                <Grid item xs={6}>
                    <AgentSmartDropDown 
                        label="Agent"
                        value={agentCode}
                        onChange={val => setAgentCode(val)}
                        required={true}
                    />
                </Grid>                
                <Grid item xs={6}>
                    <TargetComparison barChart={[...data.barChart]}/>
                </Grid>
                <Grid item xs={6}>
                    <ProductsSold pieChart={[...data.pieChart]}/>
                </Grid>
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}

export default connect(mapStateToProps)(Performance)