import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const getPaymentType = (source, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}payment-type`,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data && response.data.length) {
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                }
                reject(response);
            })
            .catch(error => reject(error))
    })
}

