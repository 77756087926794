import {
    Paper,
    withStyles
} from '@material-ui/core';
import {
  Chart,
  PieSeries,
  Title,
  Legend
} from '@devexpress/dx-react-chart-material-ui';

const styles = theme => ({
    item: {
      flexDirection: 'row-reverse',
    },
    label: {
      textAlign: 'right',
    },
});
  
const Item = withStyles(styles)(({ classes, ...restProps }) => (
    <Legend.Item {...restProps} className={classes.item} />
));
  
const Label = withStyles(styles)(({ classes, ...restProps }) => (
    <Legend.Label {...restProps} className={classes.label} />
));

const ProductsSold = props => {

    const {pieChart} = props;
    return <Paper>                
                <Chart data={pieChart}>
                    <PieSeries valueField="value" argumentField="label"/>
                    <Title text="Products Sold"/>
                    <Legend itemComponent={Item} labelComponent={Label} />
                </Chart>
            </Paper>
}

export default ProductsSold;