import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import {Animation} from '@devexpress/dx-react-chart';

const TargetComparison = props => {

    const {barChart} = props;    
 
    return <Paper>
                <Chart data={barChart}>
                    <ArgumentAxis />
                    <ValueAxis max={40}/>
                    <BarSeries valueField="value" argumentField="key"/>
                    <Title text="Performance"/>
                    <Animation/>
                </Chart>
            </Paper>
}

export default TargetComparison;