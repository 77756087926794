import {useEffect} from 'react';
import {
    Grid,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    Divider
} from '@material-ui/core';
import TextField from "../../../components/FormInputs/TextField/TextField";
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import autoPaymentDays from "../../../constants/autoPaymentDays";
import moment from 'moment';
import PaymentCardInput from "../../../components/PaymentCardInput/PaymentCardInput";
import PaymentTypeSmartDropDown from "../../common/PaymentTypeSmartDropDown/PaymentTypeSmartDropDown";
import CheckboxField from "../../../components/FormInputs/CheckboxField/CheckboxField";

const useStyles = makeStyles(theme => ({
    lineItem: {
        padding: '0px'
    }
}))

const StepThree = (props) => {
    const {data, stepNum, errors, setErrors, hasError, getErrorMessage, onChange, onRequiredChange} = props;

    const {nameOnTheCard, cardNumber, cardExpiryDate, cardCVVNumber, enableAutomaticPayments,
            dayOfAutomaticPayment, paymentType} = data[stepNum];

    const {oneTimeFee, monthlyFee, monthsPay, totalPayment} = data[1];

    useEffect(() => {
        if(enableAutomaticPayments.value && !dayOfAutomaticPayment.value) {
            setErrors(prevState => {
                return {
                    ...prevState,
                    dayOfAutomaticPayment: 'Day of auto payment is required if enable auto payment is true.'
                }
            });
        }
        else {
            if(errors.dayOfAutomaticPayment) {
                setErrors(prevState => {
                    const newErrors = {...prevState};
                    delete newErrors['dayOfAutomaticPayment'];
                    return newErrors;
                });
            }
        }

    }, [enableAutomaticPayments, dayOfAutomaticPayment]);

    useEffect(() => {
        const isRequired = paymentType.value === 'C' ? true : false;
        onRequiredChange('nameOnTheCard', isRequired);
        onRequiredChange('cardNumber', isRequired);
        onRequiredChange('cardExpiryDate', isRequired);
        onRequiredChange('cardCVVNumber', isRequired);

        if(!isRequired) {
            setErrors(prevState => {
                const newErrors = {...prevState};
                delete newErrors['nameOnTheCard'];
                delete newErrors['cardNumber'];
                delete newErrors['cardExpiryDate'];
                delete newErrors['cardCVVNumber'];
                return newErrors;
            });
        }
    }, [paymentType])

    const classes = useStyles();

    return <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PaymentTypeSmartDropDown
                        label="Payment Type"
                        value={paymentType.value}
                        onChange={val => onChange('paymentType', val)}
                        required={paymentType.required}
                        error={hasError('paymentType')}
                        helperText={getErrorMessage('paymentType')} 
                    />
                </Grid>
                {
                    paymentType.value === 'C' ?
                    <>
                        <Grid item xs={12}>
                            <PaymentCardInput
                                cardDetails={{
                                    cardNumber: {
                                        value: cardNumber.value,
                                        required: cardNumber.required,
                                        error: hasError('cardNumber'),
                                        helperText: getErrorMessage('cardNumber')
                                    },
                                    cardExpiryDate: {
                                        value: cardExpiryDate.value,
                                        required: cardExpiryDate.required,
                                        error: hasError('cardExpiryDate'),
                                        helperText: getErrorMessage('cardExpiryDate')
                                    },
                                    cardCVVNumber: {
                                        value: cardCVVNumber.value,
                                        required: cardCVVNumber.required,
                                        error: hasError('cardCVVNumber'),
                                        helperText: getErrorMessage('cardCVVNumber')
                                    }
                                }}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField 
                                label="Name On The Card"
                                value={nameOnTheCard.value}
                                onChange={val => onChange('nameOnTheCard', val)}
                                required={nameOnTheCard.required}
                                error={hasError('nameOnTheCard')}
                                disabled={false}
                                helperText={getErrorMessage('nameOnTheCard')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField 
                                label="Enable Automatic Payments"
                                value={enableAutomaticPayments.value}
                                onChange={val => {
                                    onChange('enableAutomaticPayments', val);
                                    if(!val) onChange('dayOfAutomaticPayment', '')
                                }}
                                size="medium"
                                name="enable-auto-payments"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteDropdown
                                label="Day Of Automatic Payment"
                                options={autoPaymentDays}
                                value={dayOfAutomaticPayment.value}
                                loading={false}
                                onChange={val => onChange('dayOfAutomaticPayment', val)}
                                required={dayOfAutomaticPayment.required}
                                error={hasError('dayOfAutomaticPayment')}
                                disabled={!enableAutomaticPayments.value}
                                helperText={getErrorMessage('dayOfAutomaticPayment')} 
                            />
                        </Grid>
                        <Grid item xs={12}><hr/></Grid>
                    </> :
                    null
                }
                <Grid item xs={12}>
                    <List aria-label="main mailbox folders">
                        <ListItem className={classes.lineItem}>                        
                            <ListItemText>{`Transaction Date: ${moment().format('MM/DD/YYYY')}`}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`One Time Fee: $${oneTimeFee.value}`}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`Monthly Fee: $${monthlyFee.value}`}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`Number Of Monthly Payments: ${monthsPay.value}`}</ListItemText>
                        </ListItem>
                        <ListItem className={classes.lineItem}>
                            <ListItemText><strong>{`Total: $${totalPayment.value}`}</strong></ListItemText>
                        </ListItem>                    
                    </List>
                </Grid>
            </Grid>
}

export default StepThree