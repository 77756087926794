import React, {useEffect, useRef, useState} from 'react';
import NativeTableHead from './TableHead';
import NativeTableRow from './TableRow';
import PropTypes from "prop-types";
import {makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    tableContainer: {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
});

const NativeTable = props => {
    const classes = useStyles(props);

    const {headCells, rows, rowTemplate, page, totalPages, loading, identifier, 
        handleSingleClickRow, tableHeight, handleDoubleClickRow, handleRowsOrderChange, 
        refreshTable, setRefreshTable, sortOrder, sortBy} = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headCells[0].id);
    const [sortedRows, setSortedRows] = useState([]);

    const tableContainerRef = useRef(null);

    useEffect(() => {
        if(sortOrder) setOrder(sortOrder)
    }, [sortOrder])

    useEffect(() => {
        if(sortBy) setOrderBy(sortBy)
    }, [sortBy])

    useEffect(() => {
        if(page === 1){
            tableContainerRef.current.scrollTop = 0;
        }
    }, [page]);

    const nativeTableScrolled = (e) => {
        // If the user has scrolled within 200px of the bottom, add more data
        const buffer = 200;
        const tableViewHeight = e.target.offsetHeight // viewport: ~500px
        const tableScrollHeight = e.target.scrollHeight // length of all table
        const scrollLocation = e.target.scrollTop; // how far user scrolled
        let limit = tableScrollHeight - tableViewHeight - buffer;

        if (!loading && scrollLocation > limit) {
            if (page < totalPages) {
                props.loadMoreRecords(e);
            }
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };      
    
    const stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = cmp(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    const desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }

    const getSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    useEffect(() => {
        if(refreshTable) {
            setSortedRows(stableSort(rows, getSorting(order, orderBy)))
        }
    }, [rows, refreshTable])

    //Any time sort order changes, sort the rows and return sorted rows back to parent component
    useEffect(() => {
        setSortedRows(stableSort(rows, getSorting(order, orderBy)))
    }, [order, orderBy])

    useEffect(() => {
        handleRowsOrderChange(sortedRows)
        setRefreshTable(false)
    }, [sortedRows])

    return <Paper style={{position: 'relative', margin: '20px 0px'}}>

        <TableContainer className={classes.tableContainer} onScroll={nativeTableScrolled} ref={tableContainerRef} style={{height: `${tableHeight}vh`}}>
            <Table
                size="medium"
                stickyHeader
                aria-label="sticky table"
            >
                {
                    headCells ?
                        <NativeTableHead
                            headCells={headCells}
                            order = {order}
                            orderBy = {orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        :
                        null
                }
                {
                    loading ?
                    <TableBody>
                    {
                        [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(num => {
                            return <TableRow key={num}>
                                {
                                    headCells.map(hc => {
                                        if(hc.showColumn) {
                                            return <TableCell key={`${hc.id}-${num}`}>
                                                <Skeleton variant="rect" height="100" width="100" />
                                            </TableCell>
                                        }
                                        return null
                                    })
                                }
                            </TableRow>
                        })
                    }
                    </TableBody> :
                    <TableBody>
                    {
                        sortedRows
                        .map((row, index) => {
                            return <NativeTableRow
                                key={index}
                                data={row}
                                columnOrder={headCells}
                                onRowClick={handleSingleClickRow}
                                handleDoubleClickRow={handleDoubleClickRow}
                                identifier={identifier}>
                                {rowTemplate}
                            </NativeTableRow>
                        })
                    }
                    </TableBody>
                }
            </Table>
        </TableContainer>
    </Paper>
}

NativeTable.propTypes = {
    /*
    headCells prop is used to render table head and it must be an array of object. Object must be in following format - 
    [
        {id: 'taskNumber', label: 'Task Number', showColumn: true, filtertable: false, keywordBasedSearchFilter: false, sortable: 'true'},
        {id: 'description', label: 'Description', showColumn: true, filtertable: false, keywordBasedSearchFilter: false, sortable: 'true'},
    ]
    */
    headCells: PropTypes.array.isRequired,
    /*
    rows prop is used to render table body and it must be an array of object. 
    Table body works with object in any format provided that rowTemplate component handles it in right way.
    Table body loops through rows and forwards each row object to rowTemplate as it is.
    If now rows, pass an empty array.
    */
    rows: PropTypes.array.isRequired,
    /*
    rowTemplate is a JSX component that renders each row in table body.
    */
    rowTemplate: PropTypes.element.isRequired,
    /*
    page and totalPages help with the lazy loading.
    Developer can decide how many rows he wants to render in first page i.e. first render
    On scrolling to bottom next page will be rendered and page will be set to next page number
    This happens until we react last page i.e. page === totalPages
    */
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    //loading prop displays/hides loading spinner
    loading: PropTypes.bool.isRequired,
    /*
    loadMoreRecords gets called if scroll reaches bottom and there are more pages left to render
    */
    loadMoreRecords: PropTypes.func,
    loadMoreRecords: (props, propName, componentName) => {
        if((props['page'] < props['totalPages']) && !props[propName]) {
            return new Error(`Prop ${propName} is required if totalPages > page i.e. there are more records to render in ${componentName}`);
        } 
    },
    /*
    identifier prop should be an id of column that uniquely identifies the row
    On row click or multiple row selection, row[column.id] would be returned
    This way parent components knows which row was clicked on or selected.
    For e.g. taskNumber, description, clientGroup etc
    */
    identifier: PropTypes.string.isRequired,
    /*
    handleRowSelection prop should be able to receive one parameter. 
    This paramenter can be an array of identifiers of selected rows or single identifier of selected row
    depending on parent component allowing/disallowing multiple rows selection
    */
    handleSingleClickRow: PropTypes.func.isRequired,
    /*
    tableHeight prop is optional and takes a number to set table height in terms of viewport height.
    */
    tableHeight: PropTypes.number,
    /*
    handleDoubleClickRow prop should be able to receive one parameter. 
    This table returns identifier of double clicked row
    */
    handleDoubleClickRow: PropTypes.func,
    /*
    handleRowsOrderChange function prop should be able to receive one param - sortedRows
    This param is an sorted array of row objects. This sort order will be dictated by user
    This helps to keep rows order in sync for Table and NextPrevDialog
    */
    handleRowsOrderChange: PropTypes.func.isRequired,
    /*
    In order to break the endless loop that results due to two way binding of rows between parent component and Table component we use 
    boolean prop refreshTable
    Anytime rows data changes in parent component, it is set to true
    */
    refreshTable: PropTypes.bool.isRequired,
    /*
    If rows data changes in Table component, refreshTable boolean flag in parent component should be set to false
    This prop function allows to do that.
    */
    setRefreshTable: PropTypes.func.isRequired,
    /*
    sortOrder and sortBy props can be used to set default sorting
    sortBy should have id of one of the columns passed in prop headCells array
    */
    sortOrder: PropTypes.oneOf(['asc', 'desc']),
    soryBy: PropTypes.string
}

export default NativeTable;
