import {
    Grid
} from '@material-ui/core';
import TextField from "../../../components/FormInputs/TextField/TextField";
import ManageDependents from '../../common/ManageDependents/ManageDependents';
import Address from "../../common/Address/Address";
import GenderSmartDropDown from "../../common/GenderSmartDropDown/GenderSmartDropDown";
import CenterSmartDropDown from "../../common/CenterSmartDropDown/CenterSmartDropDown";
import AgentSmartDropDown from "../../common/AgentSmartDropDown/AgentSmartDropDown";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const StepOne = (props) => {
    const {data, stepNum, hasError, getErrorMessage, onChange} = props;
    const {firstName, middleName, lastName, dateOfBirth, gender, address,
        primaryPhone, emergencyPhone, center, email, dependents, agentCode} = data[stepNum];

return <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField 
                        label="First Name"
                        value={firstName.value}
                        onChange={val => onChange('firstName', val)}
                        required={firstName.required}
                        error={hasError('firstName')}
                        disabled={false}
                        helperText={getErrorMessage('firstName')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Middle Name"
                        value={middleName.value}
                        onChange={val => onChange('middleName', val)}
                        required={middleName.required}
                        error={hasError('middleName')}
                        disabled={false}
                        helperText={getErrorMessage('middleName')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Last Name"
                        value={lastName.value}
                        onChange={val => onChange('lastName', val)}
                        required={lastName.required}
                        error={hasError('lastName')}
                        disabled={false}
                        helperText={getErrorMessage('lastName')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                        <KeyboardDatePicker
                            label="DOB"
                            clearable
                            value={dateOfBirth.value}
                            onChange={date => {
                                if( date && moment(date).isValid() && moment(date).isSameOrBefore(new Date()) ) {
                                    onChange('dateOfBirth', moment(date).format('YYYY-MM-DD'))
                                }
                                else onChange('dateOfBirth', null);
                            }}
                            format="MM/DD/yyyy"
                            inputVariant="outlined"
                            margin="none"
                            size='small'
                            disableFuture={true}
                            InputLabelProps={{
                                margin: 'dense'
                            }}
                            fullWidth
                            required={dateOfBirth.required}
                            error={hasError('dateOfBirth')}
                            helperText={getErrorMessage('dateOfBirth')}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                    <GenderSmartDropDown
                        label="Gender"
                        value={gender.value}
                        onChange={val => onChange('gender', val)}
                        required={gender.required}
                        error={hasError('gender')}
                        disabled={false}
                        helperText={getErrorMessage('gender')} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <Address 
                        address={address.value}
                        onAddressChange={val => onChange('address', val)}
                        showErrorMessage={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Primary Phone"
                        value={primaryPhone.value}
                        onChange={val => onChange('primaryPhone', val)}
                        required={primaryPhone.required}
                        error={hasError('primaryPhone')}
                        disabled={false}
                        helperText={getErrorMessage('primaryPhone')}
                        isNumericOnly
                        isPhoneNumber={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Emergency Phone"
                        value={emergencyPhone.value}
                        onChange={val => onChange('emergencyPhone', val)}
                        required={emergencyPhone.required}
                        error={hasError('emergencyPhone')}
                        disabled={false}
                        helperText={getErrorMessage('emergencyPhone')}
                        isNumericOnly
                        isPhoneNumber={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Email"
                        value={email.value}
                        onChange={val => onChange('email', val)}
                        required={email.required}
                        error={hasError('email')}
                        disabled={false}
                        helperText={getErrorMessage('email')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CenterSmartDropDown
                        label="Primary Center"
                        value={center.value}
                        onChange={val => onChange('center', val)}
                        required={center.required}
                        error={hasError('center')}
                        disabled={false}
                        helperText={getErrorMessage('center')} 
                    />
                </Grid>
                <Grid item xs={4}>
                    <AgentSmartDropDown
                        label="Agent"
                        value={agentCode.value}
                        onChange={val => onChange('agentCode', val)}
                        required={agentCode.required}
                        error={hasError('agentCode')}
                        disabled={false}
                        helperText={getErrorMessage('agentCode')} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <ManageDependents 
                        dependents={dependents.value}
                        onChange={newDependents => onChange('dependents', newDependents)}
                    />
                </Grid>
            </Grid>
}

export default StepOne