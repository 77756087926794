import React from 'react';
import {TableRow} from '@material-ui/core';
import PropTypes from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import ClickPreventionOnDoubleClick from "../ClickPreventionOnDoubleClick/ClickPreventionOnDoubleClick";

const useStyles = makeStyles(theme => ({
    tableRow: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(12,35,64,0.1)',
            margin: '20px 50px',
            boxShadow: 'inset 0px 0px 0px 5px white',
            borderRadius: '6px',
            boxSizing: 'border-box',
        }
    },
    selected: {
        "&:focus": {
            backgroundColor: props => props.allowRowClickSelection && 'rgba(12,35,64,0.1)'
            }
        }
}));

const NativeTableRow = (props) => {
    const { data, columnOrder, onRowClick, handleDoubleClickRow, identifier } = props;

    /*
    In order to prevent firing click event on double clicking, we have created ClickPreventionOnDoubleClick component
    Use event handlers returned by this component to handle single and double click.
    */
    const [handleClick, handleDoubleClick] = ClickPreventionOnDoubleClick(() => {
        onRowClick(data[identifier]);
    }, () => {
        /*
        handleDoubleClickRow is an optional prop in Table component. 
        In case user hasn't passed this prop, we execute onRowClick function prop which is required in Table component.
        */
        typeof handleDoubleClickRow === 'function' ? handleDoubleClickRow(data[identifier]) : onRowClick(data[identifier]); 
    })

    const classes = useStyles(props);
    const rowTemplateComponent = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            data : data,
            columnOrder: columnOrder,
            ...child.props
        });
    });

    return <TableRow
        /*
        Use handlers returned by ClickPreventionOnDoubleClick component to handle onClick and onDoubleClick
        */
        onClick = {handleClick}
        onDoubleClick = {handleDoubleClick}
        tabIndex={-1}
        className = {onRowClick ? [classes.tableRow, classes.selected].join(' ') : ''}
        key={data.id} >
            {rowTemplateComponent}
    </TableRow>;
}

NativeTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
    columnOrder: PropTypes.array,
    onRowClick: PropTypes.func
}

export default NativeTableRow;
