import {useState, useEffect} from 'react';
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {getDiscount} from "../../../apiCalls/getDiscount";
import axios from 'axios';
import PropTypes from "prop-types";
import Notification from "../../../components/Notification/Notification";
import {connect} from 'react-redux';

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
}

const DiscountSmartDropDown = props => {

    const {label, value, onChange, required, disabled, error, helperText, accountInfo, discountOptions} = props;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if (discountOptions) {
            setOptions(discountOptions);     
        } else if(authToken) {
            setLoading(true)
            getDiscount(source, authToken)
            .then(response => {
                setOptions(response.map(d => {
                    return {
                        label: d.discountCode,
                        value: d.discountCode,
                        isPercentage: d.isPercentage,
                        discountedAmount: d.discountedAmount
                    }
                }));
                
                setLoading(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled discount options fetching");
                } else {
                    console.log('Failed to fetch discount options', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch discount options, please try again"
                    });
                }
            });
        }

        return () => {
            source.cancel();
        };
    }, [authToken]);

    useEffect(() => {
        setOptions(discountOptions)
    }, [discountOptions])
    
    const handleChange = (selectedOption) => {
        onChange(options.find(item => item.value === selectedOption))
    }

    return <>
                <AutocompleteDropdown
                    label={label}
                    options={options}
                    value={value}
                    loading={loading}
                    onChange={handleChange}
                    required={required}
                    disabled={disabled}
                    error={error}
                    helperText={helperText} 
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </>
}

DiscountSmartDropDown.propTypes = {
    label: PropTypes.string.isRequired, 
    value: PropTypes.string.isRequired, 
    onChange: PropTypes.func.isRequired, 
    required: PropTypes.bool,
    disabled: PropTypes.bool, 
    error: PropTypes.bool, 
    helperText: PropTypes.string
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(DiscountSmartDropDown);
