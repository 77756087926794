const applicationStatus = [
    {
        label: 'Processed',
        value: 'Processed'
    },
    {
        label: 'Draft',
        value: 'Draft'
    }
]

export default applicationStatus;