import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const getApplicationById = (source, applicationId, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}membership-application?applicationId=${applicationId}`,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data && response.data.length) {
                        resolve(response.data[0]);
                    }
                }
                reject(response);
            })
            .catch(error => reject(error))
    })
}