import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Button,
    Typography,
    CircularProgress,
    AppBar,
    Grid,
    Paper
} from "@material-ui/core";
import Stepper from "../Stepper/Stepper";
import {checkEmailValidity} from "../../helperFunctions/checkEmailValidity";
import Notification from "../../components/Notification/Notification";
import {checkCreditCardValidity} from "../../helperFunctions/checkCreditCardValidity";
import {checkCVVValidity} from "../../helperFunctions/checkCVVValidity";
import validPhoneNumber from '../../helperFunctions/checkPhoneNumber';

const useStyles = makeStyles({
    appBar: {
        textAlign: 'right',
        backgroundColor: "white",
        bottom: 0,
        padding:10,
        top:"auto"
    },
    stepContainer: {
        padding: '20px',
        height: 'auto'
    }
});

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const FormWizard = props => {
    const {steps, onSubmit, handleNextClick, allowAnotherSubmit, resetAllowAnotherSubmit} = props;    
    const classes = useStyles();

    const [data, setData] = useState(steps.map(i => i.data));
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [clonedComponents, setClonedComponents] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [noWayBack, setNoWayBack] = useState(false);

    useEffect(() => {
        setData(steps.map(i => i.data));
    }, [steps])

    useEffect(() => {
        if(allowAnotherSubmit) {
            setDisableSubmit(false);
            setSubmitLoading(false);
            if(resetAllowAnotherSubmit) resetAllowAnotherSubmit();    
        }
    }, [allowAnotherSubmit])

    useEffect(() => {
        const newErrors = {}
        for(let i = 0; i < steps.length; i++) {
            const activeStepData = data[i];
            for (let key in errors) {
                if (activeStepData[key]) {
                    newErrors[key] = errors[key];
                }
            }

            for (let key in activeStepData) {
                if (activeStepData[key].required) {
                    if (Array.isArray(activeStepData[key].value)) {
                        if (activeStepData[key].value.length < 1) {
                            newErrors[key] = 'Field is required!';
                        } else {
                            delete newErrors[key];
                        }
                    } else if (activeStepData[key].value === null || 
                                activeStepData[key].value.toString().trim().length === 0 ||
                                (key === 'address' && typeof activeStepData[key].value === 'object' && !activeStepData[key].value.isValid) ||
                                (key === 'email' && !checkEmailValidity(activeStepData[key].value))
                                ) {
                        newErrors[key] = 'Field is required!';
                    } else if ( key === 'cardNumber' && !checkCreditCardValidity(activeStepData[key].value) ) {
                        newErrors[key] = 'Invalid CC number';
                    } else if ( key === 'cardCVVNumber' && !checkCVVValidity(activeStepData['cardNumber'].value, activeStepData[key].value) ) {
                        newErrors[key] = 'Invalid CVV';
                    }else if ((key === "emergencyPhone" || key === "primaryPhone") && !validPhoneNumber(activeStepData[key].value)){
                        newErrors[key] = `Invalid ${key === "emergencyPhone" ? 'Emergency Phone Number' : 'Primary Phone Number'}` ;
                    }
                    else {
                        delete newErrors[key];
                    }
                }
            }
        }
        if(Object.keys(newErrors).length > 0) setDisableSubmit(true);
        else setDisableSubmit(false);

    }, [data, steps, errors]);

    useEffect(() => {
        const activeStepData = data[activeStep];
        const newErrors = {};

        for (let key in errors) {
            if (activeStepData[key]) {
                newErrors[key] = errors[key];
            }
        }

        for (let key in activeStepData) {
            if (activeStepData[key].required) {
                if (Array.isArray(activeStepData[key].value)) {
                    if (activeStepData[key].value.length < 1) {
                        newErrors[key] = 'Field is required!';
                    } else {
                        delete newErrors[key];
                    }
                } else if (activeStepData[key].value === null || 
                        activeStepData[key].value.toString().trim().length === 0 ||
                        (key === 'address' && typeof activeStepData[key].value === 'object' && !activeStepData[key].value.isValid) ||
                        (key === 'email' && !checkEmailValidity(activeStepData[key].value))
                    ) {
                        newErrors[key] = 'Field is required!';
                } else if ( key === 'cardNumber' && !checkCreditCardValidity(activeStepData[key].value) ) {
                    newErrors[key] = 'Invalid CC number';
                } else if ( key === 'cardCVVNumber' && !checkCVVValidity(activeStepData['cardNumber'].value, activeStepData[key].value) ) {
                    newErrors[key] = 'Invalid CVV';
                } else if ((key === "emergencyPhone" || key === "primaryPhone") && !validPhoneNumber(activeStepData[key].value)){
                    newErrors[key] = `Invalid ${key === "emergencyPhone" ? 'Emergency Phone Number' : 'Primary Phone Number'}` ;
                }
                else {
                    delete newErrors[key];
                }
            }
        }

        setErrors(newErrors);

    }, [data, activeStep]);

    useEffect(() => {
        const arr = steps.map((step, i) => {
            return React.cloneElement(step.component,
                {
                    data: data,
                    stepNum: i,
                    activeStep: activeStep,
                    errors: errors,
                    setErrors: setErrors,
                    hasError: hasError,
                    getErrorMessage: getErrorMessage,
                    onChange: handleFieldChange,
                    onRequiredChange: handleRequiredChange
                });
        });
        setClonedComponents(arr);
    }, [data, steps, errors]);

    const handleStepBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepNext = () => {
        setNextLoading(true);
        const result = {};
        data.forEach(arr => {
            for (let key in arr) {
                if (Array.isArray(arr[key].value)) {
                    if (arr[key].value.length >= 1) {
                        result[key] = arr[key].value;
                    } 
                } else if ( !( arr[key]?.value === null || 
                                arr[key].value?.toString().trim().length === 0 ||
                            (key === 'address' && typeof arr[key].value === 'object' && !arr[key].value.isValid) ||
                            (key === 'email' && !checkEmailValidity(arr[key].value)) ||
                            (key === 'cardNumber' && !checkEmailValidity(arr[key].value)) ||
                            (key === 'cardCVVNumber' && !checkCVVValidity(arr[key].value))
                ) ) {
                    result[key] = arr[key].value;
                }
            }
        });
        handleNextClick(result)
        .then(response => {
            if(response && response.data && response.data.length) {
                handleFieldChange('applicationId', response.data[0].applicationId, 0);
                handleFieldChange('memberId', response.data[0].members[0].memberId, 0);
                handleFieldChange('dependents', 
                    response.data[0].members
                    .filter(it => it.relationshipTypeId !== 'P')
                    .map((mem, memIndex) => {
                        return {
                            applicationId: response.data[0].applicationId,
                            memberId: mem.memberId,
                            dateOfBirth: new Date(mem.dateOfBirth),
                            firstName: mem.firstName,
                            lastName: mem.lastName,
                            phone: mem.phoneNumber,
                            gender: mem.genderId,
                            relationship: data[0].dependents.value[memIndex].relationship
                        }
                    }), 0);
            }
            setNextLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch(error => {
            console.log('Failed to save partial application', error);
            setNextLoading(false);
            setNotification({
                open: true,
                type: "error",
                message: "We couldn't save partial application, please try again"
            });            
        });
    };

    const handleFieldChange = (field, value, stepIndex) => {
        setData(prevState => {
            return prevState.map((step, i) => {
                if (((stepIndex && stepIndex === i) || activeStep === i) && step[field]) {
                    return {
                        ...step,
                        [field]: {
                            ...step[field],
                            value: value
                        }
                    }
                }
                return step;
            });
        });
    };

    const handleRequiredChange = (field, value, stepIndex) => {
        setData(prevState => {
            return prevState.map((step, i) => {
                if (((stepIndex && stepIndex === i) || activeStep === i) && step[field]) {
                    return {
                        ...step,
                        [field]: {
                            ...step[field],
                            required: value
                        }
                    }
                }
                return step;
            });
        });
    }

    const handleStepClick = (index) => {
        if (!disableSubmit && !Object.keys(errors).length > 0) {
            setActiveStep(index)
        }
    }

    const handleSubmit = () => {        
        setDisableSubmit(true);
        setSubmitLoading(true);

        const result = {};
        data.forEach(arr => {
            for (let key in arr) {
                        result[key] = arr[key].value;
                    } 
        });

        onSubmit(result);
    };

    const hasError = (key) => {
        if (errors[key]) {
            return true;
        }
        return false;
    };

    const getErrorMessage = (key) => {
        if (errors[key]) {
            return errors[key];
        }
        return null;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <form autoComplete="off">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        onStepClick={handleStepClick}
                    >
                        {
                            clonedComponents.map((component, index) => {
                                return <Typography component='div'
                                                   key={'stepComponent-' + index}
                                                   hidden={index !== activeStep}
                                                   style={{padding: '20px'}}
                                >
                                    <Paper elevation={3} className={classes.stepContainer}>{component}</Paper>
                                </Typography>
                            })
                        }
                    </Stepper>
                </form>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <div>
                        <Button variant="outlined"
                                style={{marginRight: 5}}
                                disabled={activeStep === 0 || submitLoading || noWayBack}
                                onClick={handleStepBack}
                        >
                            Back
                        </Button>

                        <Button variant="contained"
                                style={{marginRight: 5}}
                                color="primary"
                                onClick={handleStepNext}
                                disabled={activeStep === steps.length - 1 || Object.keys(errors).length > 0 || nextLoading}
                        >
                            {
                                nextLoading ?
                                    <CircularProgress
                                        style={{color: 'white', width: 14, height: 14, marginRight: 5}}/>
                                    :
                                    null
                            }
                            Next
                        </Button>

                        <Button variant="contained"
                                style={{marginRight: 5, color: 'white'}}
                                color="secondary"
                                onClick={() => {
                                    setNoWayBack(true);
                                    handleSubmit();
                                }}
                                disabled={disableSubmit || Object.keys(errors).length > 0}
                        >
                            {
                                submitLoading ?
                                    <CircularProgress
                                        style={{color: 'white', width: 14, height: 14, marginRight: 5}}/>
                                    :
                                    null
                            }
                            Submit
                        </Button>
                    </div>
                </AppBar>
            </Grid>
            <Notification
                open={notification.open}
                type={notification.type}
                message={notification.message}
                onClose={() => setNotification({...DEFAULT_NOTIFICATION})}
            />
        </Grid>
    );
};

FormWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    handleNextClick: PropTypes.func,
    allowAnotherSubmit: PropTypes.bool,
    resetAllowAnotherSubmit: PropTypes.func
};

export default FormWizard;
