import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import moment from 'moment';

const CommissionRowTemplate = props => {
    const {data, columnOrder} = props;

    return columnOrder.map(column => {
        if(column.showColumn) {
            if(column.id === 'status') {
                return <TableCell align="left" key={column.id}><Typography>{data.isProcessed ? "Processed" : "Draft"}</Typography></TableCell>
            }
            if(column.id === 'commissionDate') {
                return <TableCell align="left" key={column.id}><Typography>{moment(data[column.id]).format('MM/DD/YYYY')}</Typography></TableCell>
            }
            return <TableCell align="left" key={column.id}><Typography>{data[column.id]}</Typography></TableCell>
        }
        return null;
    })
}

export default CommissionRowTemplate;