import React from 'react';
import {TableCell, Tooltip, Typography} from '@material-ui/core';
import moment from 'moment';

const ApplicationRowTemplate = props => {
    const {data, columnOrder} = props;

    return columnOrder.map(column => {
        if(column.showColumn) {
            if(column.id === 'status') {
                return <TableCell align="left" key={column.id}><Typography>{data.membershipId > 0 ? (data.isActive ? "Processed" : "Inactive") : "Draft"}</Typography></TableCell>
            }

            
            if(column.id === 'startDate'){
                return <TableCell align="left" key={column.id}><Typography>{moment(data.startDate).format('MM/DD/YYYY')}</Typography></TableCell>
            }
            
            if(column.id === 'agentCode') {
                return <Tooltip title={`${data.agentFirstName} ${data.agentLastName}`} aria-label="add">
                    <TableCell align="left" key={column.id}><Typography>{data.agentCode}</Typography></TableCell>
                </Tooltip>;
            }            

            return <TableCell align="left" key={column.id}><Typography>{(column.id === 'membershipId' && data[column.id] <= 0) ? '': data[column.id]}</Typography></TableCell>
        }
        return null;
    })
}

export default ApplicationRowTemplate;