import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const saveApplication = (source, application, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}membership-application`,
            {
                ...application
            },
            {
                cancelToken: source.token                
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}