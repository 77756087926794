const local = {
    functionsURL: "http://localhost:7071/api/",
    azureADRedirectURI: "http://localhost:3000",
    agentManagerGroupId: "456f2aeb-3fbc-43cd-ace4-05226dc42928"
}

const dev = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-agent-manager-webapp.azurewebsites.net",
    agentManagerGroupId: "456f2aeb-3fbc-43cd-ace4-05226dc42928"
}

const test = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-agent-manager-webapp.azurewebsites.net",
    agentManagerGroupId: "456f2aeb-3fbc-43cd-ace4-05226dc42928"
}

const prod = {
    functionsURL: "https://prod-omp-functionapp.azurewebsites.net/api/",
    azureADRedirectURI: "https://prod-omp-agent-manager-webapp.azurewebsites.net",
    agentManagerGroupId: "95d1bebb-ab2b-474b-a4e0-7f3231be700e"
}

const environment = () => {
    switch(process.env.REACT_APP_ENVIRONMENT) {
        case 'PROD':
            return prod;
        case 'TEST':
            return test;
        case 'DEV':
            return dev;
        case 'LOCAL':
            return local;
        default:
            return dev;
    }
};

export default environment;