import {useState, useEffect} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    makeStyles
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import AutocompleteDropdown from "../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import applicationStatus from "../../constants/applicationStatus";
import Button from "../../components/FormInputs/Button/Button";
import PropTypes from 'prop-types';
import PlanInfoSmartDropDown from "../common/PlanInfoSmartDropDown/PlanInfoSmartDropDown";
import MembershipTypeSmartDropDown from "../common/MembershipTypeSmartDropDown/MembershipTypeSmartDropDown";
import AgentSmartDropDown from "../common/AgentSmartDropDown/AgentSmartDropDown";

const DEFAULT_FORM_VALUES = {
    applicationNumber: '',
    applicationDate: null,
    memberName: '',
    planType: null,
    membershipType: '',
    status: '',
    agentCode: ''
}

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

const ApplicationFilters = (props) => {

    const [expanded, setExpanded] = useState(false);
    const [formValues, setFormValues] = useState({...DEFAULT_FORM_VALUES});
    const [filterCount, setFilterCount] = useState(0);

    const {onFilterChange} = props;
    const classes = useStyles();

    useEffect(() => {
        
    }, [formValues])

    const handleFormChange = (key, value) => {
        setFormValues(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    const handleApplyFilters = () => {
        let count = 0;
        for(const field in formValues) {
            if(formValues[field]) count = count+1;
        }
        setFilterCount(count);
        onFilterChange(formValues);
        setExpanded(false);
    }

    const handleClearFilters = () => {
        setFormValues({...DEFAULT_FORM_VALUES});
        setFilterCount(0);
        onFilterChange({...DEFAULT_FORM_VALUES});
        setExpanded(false);
    }

    return <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography className={classes.heading}><strong>Filters</strong></Typography>
                    <Typography className={classes.secondaryHeading}>{`${filterCount} filter(s) applied`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField 
                                label="Application Number"
                                value={formValues.applicationNumber}
                                onChange={val => handleFormChange('applicationNumber', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField 
                                label="Application Date"
                                fullWidth={true}
                                value={formValues.applicationDate}
                                onChange={date => handleFormChange('applicationDate', date)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField 
                                label="Member Name"
                                value={formValues.memberName}
                                onChange={val => handleFormChange('memberName', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PlanInfoSmartDropDown 
                                label="Plan Type"
                                value={formValues.planType}
                                onChange={val => handleFormChange('planType', val)}
                                membershipTypeId={formValues.membershipType} 
                                blankAllowed={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MembershipTypeSmartDropDown 
                                label="Membership Type"
                                value={formValues.membershipType}
                                onChange={val => handleFormChange('membershipType', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteDropdown
                                label="Status"
                                options={applicationStatus}
                                value={formValues.status}
                                loading={false}
                                onChange={val => handleFormChange('status', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AgentSmartDropDown 
                                label="Agent"
                                value={formValues.agentCode}
                                onChange={val => handleFormChange('agentCode', val)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleApplyFilters}
                                size="small"
                                label="Apply Filters"
                            />
                            &nbsp;&nbsp;
                            <Button
                                onClick={handleClearFilters}
                                size="small"
                                label="Clear Filters"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
}

ApplicationFilters.propTypes = {
    onFilterChange: PropTypes.func.isRequired
}

export default ApplicationFilters;