import React from 'react';
import clsx from 'clsx';
import { 
  makeStyles, 
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid
} from '@material-ui/core';
import {
  Menu,
  ChevronLeft,
  ChevronRight
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import companyLogo from "../../assets/media/companyLogo.png";
import {COLOR_PALETTE} from "../../config/colorPalette";
import {connect} from 'react-redux';
import stethoscope from "../../assets/media/stethoscope.png";
import ReportDropDown from '../../features/common/ReportDropDown/ReportDropDown';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: COLOR_PALETTE.orange.opacity100
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    overflowY: 'hidden',
    marginTop: 65,
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: COLOR_PALETTE.green.opacity100
  },
  agentInfo: {
    color: COLOR_PALETTE.green.opacity100,
    float: 'right',
    padding: '2px 6px',
    fontSize: 'sm',
    verticalAlign: 'middle'
  },
  padded: {
    paddingTop: theme.spacing(1)
  }
}));

const Navigation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const {routes, children, accountInfo, logout} = props

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={1}><IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <Menu />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" noWrap>
                <img src={companyLogo} alt="Company Logo" height="40" />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <ReportDropDown></ReportDropDown>
            </Grid>
            <Grid item xs={4} className={classes.padded}>
              <img src={stethoscope} className={classes.agentInfo} alt="Company Logo" height="25" />
              <Typography className={classes.agentInfo}>
                {
                  accountInfo && accountInfo.account && accountInfo.account.name ?
                  accountInfo.account.name:
                  ''
                }
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {
            routes.map(route => {
              if(route.showInNavigation) {
                if(route.linkName === 'Signout') {
                  return <Link 
                            to={location => location}
                            style={{textDecoration: 'none'}}
                            onClick={logout} 
                            key={route.linkName} 
                            className={classes.link}> 
                          <ListItem button>
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <ListItemText primary={route.linkName}/>
                          </ListItem>
                        </Link>
                }
                return <Link to={route.path} key={route.linkName} className={classes.link}> 
                        <ListItem button>
                          <ListItemIcon>{route.icon}</ListItemIcon>
                          <ListItemText primary={route.linkName} />
                        </ListItem>
                      </Link>
              }
              else return null
            })
          }
        </List>
      </Drawer>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    accountInfo: state.accountInfo
  }
}

export default connect(mapStateToProps)(Navigation)
