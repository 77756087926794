import UseCancellablePromises from "../UseCancellablePromises/UseCancellablePromises";

const cancellablePromise = promise => {
    let isCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (isCanceled ? reject({isCanceled, value}) : resolve(value)),
            error => reject({isCanceled, error})
        )
    })

    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true)
    }
};

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const ClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
    const api = UseCancellablePromises();

    const handleClick = () => {
        api.clearPendingPromises();
        const waitForClick = cancellablePromise(delay(300));
        api.appendPendingPromises(waitForClick);

        return waitForClick.promise
            .then(() => {
                api.removePendingPromises(waitForClick);
                onClick();
            })
            .catch(errorInfo => {
                api.removePendingPromises(waitForClick);
                if(!errorInfo.isCanceled) {
                    throw errorInfo.error;
                }
            });
    }

    const handleDoubleClick = () => {
        api.clearPendingPromises();
        onDoubleClick();
    }

    return [handleClick, handleDoubleClick];
}

export default ClickPreventionOnDoubleClick;