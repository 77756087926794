import {
    TextField,
    Grid
} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import PropTypes from 'prop-types';

const PaymentCardInput = props => {

    const {cardDetails, onChange} = props;
    return <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        label="Card Number"
                        value={cardDetails.cardNumber.value}
                        required={cardDetails.cardNumber.required}
                        error={cardDetails.cardNumber.error}
                        helperText={cardDetails.cardNumber.helperText}
                        onChange={(event) => onChange('cardNumber', event.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            label="Card Expiry Date"
                            clearable
                            value={cardDetails.cardExpiryDate.value}
                            onChange={(date) => {
                                const dateInfo = moment(date).startOf('month');
                                if( date && moment(date).isValid() && dateInfo.isSameOrAfter(moment().startOf('month'))) {
                                    onChange('cardExpiryDate', date)
                                }
                                else onChange('cardExpiryDate', null);
                            }}
                            required={cardDetails.cardExpiryDate.required}
                            error={cardDetails.cardExpiryDate.error}
                            helperText={cardDetails.cardExpiryDate.helperText}
                            size='small'
                            views={['year', 'month']}
                            disablePast={true}
                            format="MM-yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        variant='outlined'
                        size='small'
                        label="CVV"
                        value={cardDetails.cardCVVNumber.value}
                        required={cardDetails.cardCVVNumber.required}
                        error={cardDetails.cardCVVNumber.error}
                        helperText={cardDetails.cardCVVNumber.helperText}
                        onChange={(event) => onChange('cardCVVNumber', event.target.value)}
                    />
                </Grid>        
            </Grid> 
}

PaymentCardInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    cardDetails: PropTypes.shape({
        cardNumber: PropTypes.shape({
            value: PropTypes.string,
            required: PropTypes.bool,
            error: PropTypes.bool,
            helperText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ])
        }),
        cardExpiryDate: PropTypes.shape({
            value: PropTypes.object,
            required: PropTypes.bool,
            error: PropTypes.bool,
            helperText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ])
        }),
        cardCVVNumber: PropTypes.shape({
            value: PropTypes.string,
            required: PropTypes.bool,
            error: PropTypes.bool,
            helperText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ])
        }) 
    }).isRequired
}

export default PaymentCardInput