import './App.css'
import React, {Fragment, useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import routes from "../../config/Routes"
import Navigation from "../../components/Navigation/Navigation";
import {setAccountInfo} from "../../redux/middleware/AccountInfoMiddleware";
import {connect} from 'react-redux';

const App = (props) => {

  const {accountInfo, logout, setAccountInfo} = props;

  useEffect(() => {
    setAccountInfo(accountInfo)
  }, [accountInfo])

  return <Fragment>
    <Router>
      <Navigation routes={routes} logout={logout}>
        <Switch>
          {
            routes.map(route => {
              return <Route exact path={route.path} key={route.linkName}>{route.component}</Route>
            })
          }
        </Switch>
      </Navigation>
    </Router>
  </Fragment>
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAccountInfo: () => dispatch(setAccountInfo(ownProps.accountInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
