import React from 'react';
import PropTypes from 'prop-types';
import {
    TableCell,
    TableRow,
    TableHead,
    TableSortLabel,
    Typography,
    makeStyles,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    label: {
        paddingLeft: theme.spacing(1),
        height: '16px',
        color: '#0C2340',
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '16px'
    },
    sortIcon: {
        //paddingLeft: theme.spacing(1),
        height: '20px',
        width: '20px',
        opacity: '0.5',
        backgroundColor: '#FFFFFF',
        color: '#0C2340'
    },
    tableHead: {
        height: '51px',
        borderRadius: '5px 5px 0 0',
        backgroundColor: '#EBEEF1',
        boxShadow: '0 0 15px 0 rgba(0,0,0,0.1)'
    },
    tableCell: {
        padding: '14px'
    }

}));

const NativeTableHead = (props) => {

    const { headCells, order, orderBy, onRequestSort} = props;

    const classes = useStyles();

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className = {classes.tableHead}>
            <TableRow>
                {
                    headCells.map(headCell => {
                        if (headCell.showColumn) {
                            return (
                                <TableCell
                                    className = {classes.tableCell}
                                    key={headCell.id}
                                    align="left"
                                    padding="default"
                                    sortDirection={orderBy === headCell.id ? order: false}
                                >
                                    <Grid
                                        container
                                        direction = "row"
                                        justify = "flex-start"
                                        alignItems = "center"
                                        style={{width: 'max-content'}}
                                    >
                                        {
                                            headCell.sortable ?
                                            <TableSortLabel
                                                active = {orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                IconComponent = {ExpandMoreIcon}
                                            >
                                                <Typography className = { classes.label }>{ headCell.label }</Typography>
                                                {
                                                    orderBy === headCell.id ? 
                                                                (
                                                                    <span style = {{
                                                                        border: 0,
                                                                        clip: 'rect(0 0 0 0)',
                                                                        height: 1,
                                                                        margin: -1,
                                                                        overflow: 'hidden',
                                                                        padding: 0,
                                                                        position: 'absolute',
                                                                        top: 20,
                                                                        width: 1,
                                                                    }}>
                                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </span>
                                                                ) : null
                                                }
                                            </TableSortLabel> : 
                                            <Typography className = { classes.label }>{ headCell.label }</Typography>

                                        }
                                        
                                    </Grid>
                                </TableCell>
                            )
                        }
                        return null;
                    })
                }
            </TableRow>
        </TableHead>
    );
}

NativeTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
};

export default NativeTableHead;
