import {useState, useEffect} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import TextField from "../../../components/FormInputs/TextField/TextField";
import Button from "../../../components/FormInputs/Button/Button";
import PropTypes from 'prop-types';
import GenderSmartDropDown from "../GenderSmartDropDown/GenderSmartDropDown";
import RelationshipTypeSmartDropDown from "../RelationshipTypeSmartDropDown/RelationshipTypeSmartDropDown";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import validPhoneNumber from '../../../helperFunctions/checkPhoneNumber';

const DEFAULT_FORM_VALUES = {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    relationship: null,
    gender: '',
    phone: '',
    memberId: '',
    applicationId: ''
}

const useStyles = makeStyles({
    container: {
        padding: '20px'
    }
})

const UpsertDependentForm = props => {

    const [formValues, setFormValues] = useState({...DEFAULT_FORM_VALUES})
    const [isFormValid, setIsFormValid] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const {upsertDependent, dependent} = props.data;

    const classes = useStyles();

    useEffect(() => {
        if(formValues.firstName && formValues.lastName && formValues.dateOfBirth && 
            formValues.relationship && formValues.gender && (validPhoneNumber(formValues.phone)  && formValues.phone !== '')) {
            setIsFormValid(true)
        }
        else setIsFormValid(false)
    }, [formValues])

    useEffect(() => {
        if(dependent) {
            setFormValues(dependent);
            setIsUpdateMode(true);
        }
    }, [dependent])

    const handleFormChange = (key, value) => {
        setFormValues(prevValues => {
            return {
                ...prevValues,
                [key]: value
            }
        })
    }
    return <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} style={{padding: '0px 8px'}}>
            <h2>{`${isUpdateMode ? 'Update' : 'Add'} Dependent`}</h2>
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="First Name"
                value={formValues.firstName}
                onChange={val => handleFormChange('firstName', val)}
                required={true}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Last Name"
                value={formValues.lastName}
                onChange={val => handleFormChange('lastName', val)}
                required={true}
            />
        </Grid>
        <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                <KeyboardDatePicker
                    label="DOB"
                    clearable
                    value={formValues.dateOfBirth}
                    onChange={date => {
                        if( date && moment(date).isValid() && moment(date).isSameOrBefore(new Date()) ) {
                            handleFormChange('dateOfBirth', moment(date).format('YYYY-MM-DD'))
                        }
                        else handleFormChange('dateOfBirth', null);
                    }}
                    format="MM/DD/yyyy"
                    inputVariant="outlined"
                    margin="none"
                    size='small'
                    disableFuture={true}
                    InputLabelProps={{
                        margin: 'dense'
                    }}
                    fullWidth
                    required={true}
                />
            </MuiPickersUtilsProvider> 
        </Grid>
        <Grid item xs={6}>
            <RelationshipTypeSmartDropDown 
                label="Relationship"
                value={formValues.relationship}
                onChange={val => handleFormChange('relationship', val)}
                required={true}
            />
        </Grid>
        <Grid item xs={6}>
            <GenderSmartDropDown 
                label="Gender"
                value={formValues.gender}
                onChange={val => handleFormChange('gender', val)}
                required={true}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Primary Phone"
                value={formValues.phone}
                onChange={val => handleFormChange('phone', val)}
                required={true}
                isNumericOnly
                isPhoneNumber={true}
                error={!validPhoneNumber(formValues.phone) || formValues.phone === ''}
                helperText={!validPhoneNumber(formValues.phone)  || formValues.phone === ''? 'Invalid phone number' : undefined}
            />
        </Grid>
        <Grid item xs={6}>
            <Button
                type="add"
                onClick={() => upsertDependent(formValues, isUpdateMode)}
                size="small"
                label={isUpdateMode ? "Save" : "Add"}
                disabled={!isFormValid}
            />
        </Grid>
    </Grid>
}

UpsertDependentForm.propTypes = {
    upsertDependent: PropTypes.func,
    dependent: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        relationship: PropTypes.string,
        gender: PropTypes.string,
        phone: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        memberId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        applicationId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    })
}

export default UpsertDependentForm