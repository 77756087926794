import {useState, useEffect, useCallback} from 'react';
import Table from "../../components/Table/Table";
import ApplicationRowTemplate from "./ApplicationRowTemplate";
import {
    Grid,
    Typography
} from '@material-ui/core';
import ApplicationFilters from "./ApplicationFilters";
import {getApplicationList} from "../../apiCalls/application/getApplicataionList";
import {debounce} from 'lodash';
import axios from 'axios';
import {useHistory} from "react-router-dom";
import Notification from "../../components/Notification/Notification";
import {getApplicationById} from "../../apiCalls/application/getApplicationById";
import {connect} from 'react-redux';
import moment from 'moment';
import ExcelExporter from '../../components/ExcelExporter/ExcelExporter';
import calculateEffectiveDate from '../../helperFunctions/effectiveDate';

const DEFAULT_APPLICATION_LIST_HEADCELLS = [
    {id: 'applicationId', label: 'Application Number', showColumn: true, sortable: 'true'},
    {id: 'membershipId', label: 'MembershipId', showColumn: true, sortable: 'true'},
    {id: 'agentCode', label: 'Agent', showColumn: true, sortable: 'true'},
    {id: 'startDate', label: 'Start Date', showColumn: true, sortable: 'true'},
    {id: 'memberName', label: 'Member Name', showColumn: true, sortable: 'true'},
    {id: 'planTypeDesc', label: 'Type of Plan', showColumn: true, sortable: 'true'},
    {id: 'primaryPhoneNumber', label: 'Phone', showColumn: true, sortable: 'true'},
    {id: 'eMail', label: 'Email', showColumn: true, sortable: 'true'},
    {id: 'status', label: 'Status', showColumn: true, sortable: 'true'},
    {id: 'primaryCenterId', label: 'Center', showColumn: true, sortable: 'true'}
];

const DEFAULT_APPLICATIONS_PER_PAGE = 50;

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const ApplicationsList = (props) => {

    const {accountInfo} = props;

    const [refreshList, setRefreshList] = useState(false);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilerValues] = useState({});
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");
    const [totalRecords, setTotalRecords] = useState([]);

    const debounceSetPageNumber = useCallback(debounce(() => setPageNumber(0), 1000), []);

    const history = useHistory();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(pageNumber === 0) {
            return setPageNumber(1);
        }

        if(authToken) {
            fetchApplicationList(authToken);
        }

        return () => source.cancel();
    }, [pageNumber, accountInfo, authToken]);

    useEffect(() => {
        if(authToken) {
            getApplicationList(source, 0, 10000, {}, authToken)
            .then((response) => setTotalRecords(response?.data))
            .catch((err) => setTotalRecords([]));
        }
    }, [authToken])

    const loadMoreRecords = () => {
        setLoading(true);
        if(pageNumber < totalPages) {
            setPageNumber(prevPageNumber => prevPageNumber+1);
        }
    }



    const fetchApplicationList = (aT) => {
        setLoading(true);
        getApplicationList(source, ((pageNumber-1) * DEFAULT_APPLICATIONS_PER_PAGE),  DEFAULT_APPLICATIONS_PER_PAGE, 
        {
            ...filterValues
        }, aT)
        .then(response => {
            if(pageNumber === 1) {
                setData(response.data);
                const absolutePages = Number(response.count) / DEFAULT_APPLICATIONS_PER_PAGE;
                setTotalPages( absolutePages === Math.floor(absolutePages) ? absolutePages : Math.floor(absolutePages) + 1 );
            }
            else {
                setData(prevData => {
                    return [
                        ...data,
                        ...response.data
                    ]
                })
            };
            setLoading(false);
            setRefreshList(true);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled applications list fetching");
            } else {
                console.log('Error fetching application list', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch applications, please try again"
                });
            }
        });
    }

    const handleDoubleClickRow = identifier => {
        getApplicationById(source, identifier, authToken)
        .then(app => {
            const dependents = [];
            if(app.members.length > 1) {
                for(let i = 0; i < app.members.length; i++) {
                    if(app.members[i].relationshipTypeId !== 'P') {
                        dependents.push({
                            firstName: app.members[i].firstName,
                            lastName: app.members[i].lastName,
                            dateOfBirth: app.members[i].dateOfBirth,
                            gender: app.members[i].genderId,
                            phone: app.members[i].phoneNumber,
                            applicationId: app.applicationId,
                            memberId: app.members[i].memberId,
                            relationship: app.members[i].relationshipType
                        });
                    }
                }
            }
            const primaryMember = app.members.filter(m => m.relationshipTypeId === 'P');
            if(app && app.membershipId < 1) {
                const formData = [{
                    address: {
                        isValid: true,
                        value: {
                            addressLine1: app.addressLine1,
                            addressLine2: app.addressLine2 || "",
                            city: app.addressCity,
                            state: app.addressState,
                            zipCode: app.addressZip.toString()
                        }
                    },
                    primaryPhone: app.primaryPhoneNumber,
                    emergencyPhone: app.emergencyPhoneNumber || "",
                    email: app.eMail,
                    center: app.primaryCenterId.toString(),
                    firstName: primaryMember[0].firstName,
                    lastName: primaryMember[0].lastName,
                    dateOfBirth: primaryMember[0].dateOfBirth,
                    gender: primaryMember[0].genderId,
                    applicationId: app.applicationId,
                    memberId: primaryMember[0].memberId,
                    dependents: dependents,
                    agentCode: app.agentCode
                }, 
                {
                    planType: {
                        fee: app.planInfo.fee,
                        membershipTypeId: app.planInfo.membershipTypeId,
                        monthlyPayment: app.planInfo.monthlyPayment,
                        monthsPay: app.planInfo.monthsPay,
                        planDesc: app.planInfo.planDesc,
                        planInfoId: app.planInfo.planInfoId,
                        planTypeId: app.planInfo.planTypeId,
                        stripeProductId: app.planInfo.stripeProductId
                    },
                    membershipType: app.planInfo.membershipTypeId,
                    oneTimeFee: app.planInfo.fee,
                    monthlyFee: app.planInfo.monthlyPayment,
                    totalPayment: ( app.planInfo.fee + (app.planInfo.monthlyPayment * app.planInfo.monthsPay) ).toFixed(2),
                    membershipEffectiveDate: calculateEffectiveDate(),
                    discountCode: app?.discount?.discountCode,
                    discountOptions: []
                }];
                if (app?.discount) {
                    let total = formData[1].totalPayment;
                    total = Number(total);
                    if(app.discount.isPercentage) { 
                        total -= ((total * app.discount.discountedAmount) / 100);
                    } else total -= app.discount.discountedAmount
                    formData[1].totalPayment = total?.toFixed(2);
                }
                history.push({
                    pathname: '/new',
                    state: {
                        formData: formData
                    }
                });
            }
            else {
                setNotification({
                    open: true,
                    type: "info",
                    message: `ApplicationId ${app.applicationId} is already processed.`
                })
            }

        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled fetching application by id");
            } else {
                console.log('Error fetching application by id', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch application in draft, please try again"
                });
            }
        })
    }

    const handleFilterChange = newFilterValues => {
        setFilerValues(prevValues => {
            return {
                ...prevValues,
                memberName: newFilterValues.memberName || undefined,
                applicationId: newFilterValues.applicationNumber || undefined,
                applicationDate: newFilterValues.applicationDate || undefined,
                planTypeId: newFilterValues.planType && newFilterValues.planType.planTypeId ? newFilterValues.planType.planTypeId : undefined,
                status: newFilterValues.status || undefined,
                membershipTypeId: newFilterValues.membershipType || undefined,
                agentCode: newFilterValues.agentCode || undefined
            }
        });
        debounceSetPageNumber();
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Applications</Typography>
                    <ExcelExporter fileName={"Application"} sheetName={"Application"}
                        columns = {DEFAULT_APPLICATION_LIST_HEADCELLS}
                        data={totalRecords}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ApplicationFilters
                        onFilterChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Table 
                        headCells={[...DEFAULT_APPLICATION_LIST_HEADCELLS]}
                        rows={data}
                        page={pageNumber}
                        totalPages={totalPages}
                        loading={loading}
                        rowTemplate={<ApplicationRowTemplate/>}
                        loadMoreRecords={loadMoreRecords}
                        identifier="applicationId"
                        handleSingleClickRow={() => console.log()}
                        handleDoubleClickRow={handleDoubleClickRow}
                        tableHeight={70}
                        handleRowsOrderChange={() => console.log()}
                        setRefreshTable={setRefreshList}
                        refreshTable={refreshList}
                        sortBy="applicationId"
                        sortOrder="asc"
                    />
                </Grid>
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}

export default connect(mapStateToProps)(ApplicationsList);