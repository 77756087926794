import {useEffect, useState} from 'react';
import {
    Grid
} from '@material-ui/core';
import axios from 'axios';
import TextField from "../../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../../components/FormInputs/DateTimeField/DateTimeField";
import PlanInfoSmartDropDown from "../../common/PlanInfoSmartDropDown/PlanInfoSmartDropDown";
import MembershipTypeSmartDropDown from "../../common/MembershipTypeSmartDropDown/MembershipTypeSmartDropDown";
import DiscountSmartDropDown from "../../common/DiscountSmartDropDown/DiscountSmartDropDown";
import {getDiscount} from "../../../apiCalls/getDiscount";
import {connect} from 'react-redux';

const StepTwo = (props) => {
    const {data, stepNum, hasError, getErrorMessage, onChange, accountInfo} = props;
    const {planType, membershipType, oneTimeFee, monthlyFee, totalPayment, membershipEffectiveDate, discountCode} = data[stepNum];
    const [discountOptions, setDiscountOptions] = useState([]);
    const {dependents} = data[0];
    const [authToken, setAuthToken] = useState("");

    const getTotal = (selectedDiscount, selectedPlan) => {
        let total = (selectedPlan?.fee + (selectedPlan?.monthlyPayment * selectedPlan?.monthsPay)) || 1;
        total = Number(total);
        if (selectedDiscount && 'discountedAmount' in selectedDiscount) {
            if(selectedDiscount.isPercentage) { 
                total -= ((total * selectedDiscount.discountedAmount) / 100);
            } else total -= selectedDiscount.discountedAmount
        }
        return total?.toFixed(2);
    }

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (authToken) {
            getDiscount(source, authToken)
            .then(response => {
                const options = response.map(d => {
                    return {
                        label: d.discountCode,
                        value: d.discountCode,
                        isPercentage: d.isPercentage,
                        discountedAmount: d.discountedAmount
                    }
                });
                setDiscountOptions(options);               
            })
            .catch(error => {
                setDiscountOptions([])
            });
        }
    }, [authToken]);

    useEffect(() => {
        let priority = 0;
        let memType = 'I'; 
        const length = dependents.value.length;
        for(let i = 0; i < length; i++) {
            if(dependents.value[i].relationship.membershipPriority > priority) {
                priority = dependents.value[i].relationship.membershipPriority;
                memType = dependents.value[i].relationship.membershipType;
            }
        }
        onChange('membershipType', memType, 1);
        onChange('oneTimeFee', '', 1);
        onChange('monthlyFee', '', 1);
        onChange('monthsPay', '', 1);
        onChange('totalPayment', '', 1);
        onChange('planType', null, 1)
    }, [dependents]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <PlanInfoSmartDropDown 
                    label="Plan"
                    blankAllowed={true}
                    value={planType.value}
                    onChange={val => {
                        if(val) {
                            onChange('oneTimeFee', val.fee, 1);
                            onChange('monthlyFee', val.monthlyPayment, 1);
                            onChange('monthsPay', val.monthsPay, 1);
                            onChange('totalPayment', 
                                getTotal(discountOptions?.find((item) => item.value === discountCode.value), val), 1);
                        }
                        else {
                            onChange('oneTimeFee', '', 1);
                            onChange('monthlyFee', '', 1);
                            onChange('monthsPay', '', 1);
                            onChange('totalPayment', '', 1);
                        }
                        onChange('planType', val, 1)
                    }}
                    required={planType.required}
                    error={hasError('planType')}
                    disabled={false}
                    helperText={getErrorMessage('planType')}
                    membershipTypeId={membershipType.value} 
                />
            </Grid>
            <Grid item xs={6}>
                <MembershipTypeSmartDropDown 
                    label="Membership Type"
                    value={membershipType.value}
                    onChange={val => onChange('membershipType', val)}
                    required={membershipType.required}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="One-Time Fee"
                    value={oneTimeFee.value}
                    onChange={val => onChange('oneTimeFee', val)}
                    required={oneTimeFee.required}
                    error={hasError('oneTimeFee')}
                    disabled={true}
                    helperText={getErrorMessage('oneTimeFee')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="Monthly Fee"
                    value={monthlyFee.value}
                    onChange={val => onChange('monthlyFee', val)}
                    required={monthlyFee.required}
                    error={hasError('monthlyFee')}
                    disabled={true}
                    helperText={getErrorMessage('monthlyFee')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="Total Payment"
                    value={totalPayment.value}
                    onChange={val => onChange('totalPayment', val)}
                    required={totalPayment.required}
                    error={hasError('totalPayment')}
                    disabled={true}
                    helperText={getErrorMessage('totalPayment')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="Membership Effective Date"
                    fullWidth={true}
                    value={membershipEffectiveDate.value}
                    onChange={date => onChange('membershipEffectiveDate', date)}
                    required={membershipEffectiveDate.required}
                    error={hasError('membershipEffectiveDate')}
                    disabled={true}
                    helperText={getErrorMessage('membershipEffectiveDate')}
                    disablePast={true}
                />
            </Grid>
            <Grid item xs={6}>
            <DiscountSmartDropDown 
                    label="Discount"
                    value={discountCode?.value}
                    onChange={val => onChange('discountCode', val)}
                    onChange={val => {
                        onChange('discountCode', val.value);
                        if(val) {
                            onChange('totalPayment', getTotal(val, planType.value));
                        }
                    }}
                    required={false}
                    disabled={false}
                    discountOptions={discountOptions}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(StepTwo);