import {useState, useEffect} from 'react';
import TextField from "../../../components/FormInputs/TextField/TextField";
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {
    Grid
} from '@material-ui/core';
import usaStates from "../../../constants/usaStates";
import lodash from 'lodash';
import PropTypes from 'prop-types';

const DEFAULT_FORM_VALUES = {
    isValid: false,
    value: { 
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: ''
    }
}

const Address = props => {

    const [formValues, setFormValues] = useState({...DEFAULT_FORM_VALUES});

    const {address, onAddressChange, showErrorMessage} = props;

    useEffect(() => {
        if(address){ 
            setFormValues(address)
        }
    }, [address])

    useEffect(() => {
        if(!lodash.isEqual(address, formValues)) {
            onAddressChange(formValues)
        }                
    }, [formValues])

    const handleFormChange = (key, value) => {
        if(key==='zipCode' && value && value.length > 5){
            return;
        }
        setFormValues(prevState => {
            let isValidAddress = true;
            if(value) {

                for(let field in prevState.value) {
                    if(field !== 'addressLine2' && field !== key && !prevState.value[field]) {
                        isValidAddress = false;
                        break;
                    }
                }

            }
            else isValidAddress = false;

            return {
                ...prevState,
                isValid: isValidAddress,
                value: {
                    ...prevState.value,
                    [key]: value
                }
            }

        });
    }

    return <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField 
                        label="Address Line 1"
                        onChange={val => handleFormChange('addressLine1', val)}
                        value={formValues.value.addressLine1}
                        error={showErrorMessage === true && !formValues.isValid}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Address Line 2"
                        onChange={val => {
                            setFormValues(prevFormValues => {
                                return {
                                    ...prevFormValues,
                                    value: {
                                        ...prevFormValues.value,
                                        addressLine2: val
                                    }
                                }
                            })
                        }}
                        value={formValues.value.addressLine2}
                        error={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="City"
                        onChange={val => handleFormChange('city', val)}
                        value={formValues.value.city}
                        error={showErrorMessage && !formValues.isValid}
                        helperText={showErrorMessage === true && !formValues.isValid ? "Valid address is required" : undefined}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteDropdown
                        label="State"
                        options={usaStates}
                        loading={false}
                        onChange={val => handleFormChange('state', val)}
                        value={formValues.value.state}
                        error={showErrorMessage === true && !formValues.isValid}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Zip Code"
                        onChange={val => handleFormChange('zipCode', val)}
                        value={formValues.value.zipCode}
                        isNumericOnly={false}
                        maxLength={5}
                        error={showErrorMessage === true && (formValues.value?.zipCode?.toString().length !== 5)}
                        helperText={showErrorMessage === true && (formValues.value?.zipCode?.toString().length !== 5) ? 'Invalid Zip Code' : undefined}
                    />
                </Grid>
            </Grid>
}

Address.propTypes = {
    address: PropTypes.shape({
        isValid: PropTypes.bool.isRequired,
        value: PropTypes.shape({
            addressLine1: PropTypes.string.isRequired,
            addressLine2: PropTypes.string,
            city: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zipCode: PropTypes.string.isRequired
        })
    }),
    onAddressChange: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.bool
}

export default Address;