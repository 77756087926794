import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button'
import { ReactComponent as ExcelIcon } from "./excel.svg";

import ReactExport from "react-export-excel";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DowloadToExcelButton = (props) => {
    const { fileName, columns, data, sheetName } = props;

    const sheet = `${sheetName}-1` || `Sheet-1`;
    const file = `${fileName || 'excel-file'}-${moment().format('YYYYMMDDHHmmss')}`;

    return (
      <div>
        {data && data.length > 0 && columns ? (
          <ExcelFile
            filename={file}
            element={(
              <Button variant="outlined" color="primary" aria-label="Export to Excel">
                <SvgIcon>
                  <ExcelIcon/>
                </SvgIcon>
              </Button>)}
            >
                <ExcelSheet data={data} name={sheet}>
                  {columns.map((c, index) => <ExcelColumn label={c.label} value={c.id} key={index}/>)}
                </ExcelSheet>
              </ExcelFile>) : null}                                                                
            </div>);
}

export default DowloadToExcelButton;