import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@material-ui/styles';
import {theme} from './config/materialui';
import {AzureAD, AuthenticationState} from 'react-aad-msal';
import {authProvider} from "../src/config/authConfig";
import {
    Grid,
    CircularProgress,
    Button
} from '@material-ui/core';
import {COLOR_PALETTE} from "../src/config/colorPalette";
import thunk from 'redux-thunk';
import RootReducer from "./redux/reducers/RootReducer";
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import environment from "./config/environment";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPONSE || compose;
const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk)));

function deleteAllCookies() {
    // document.cookie.replace(
    //     /(?<=^|;).+?(?=\=|;|$)/g, 
    //     name => window.location.hostname
    //       .split(/\.(?=[^\.]+\.)/)
    //       .reduceRight((acc, val, i, arr) => i ? arr[i]='.'+val+acc : (arr[i]='', arr), '')
    //       .map(domain => document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`)
    //   );
    try{
        // document.cookie.replace(
        //     /(?<=^|;).+?(?=\=|;|$)/g, 
        //     name => window.location.hostname
        //       .split(/\.(?=[^\.]+\.)/)
        //       .reduceRight((acc, val, i, arr) => i ? arr[i]='.'+val+acc : (arr[i]='', arr), '')
        //       .map(domain => document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`)
        //   );
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    catch(e){
        console.log('something happened that we did not remove cookies, probably Safari crap since Apple is horrible!!')
    }
}

window.localStorage.clear();
deleteAllCookies();

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <AzureAD provider={authProvider} forceLogin={true}>        
                {
                    ({login, logout, authenticationState, error, accountInfo}) => {
                        ;
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                ;
                                if(accountInfo && accountInfo.account && accountInfo.account.idToken &&
                                accountInfo.account.idToken.groups && accountInfo.account.idToken.groups.length) {
                                    ;
                                    if(accountInfo.account.idToken.groups.includes(environment().agentManagerGroupId)) {
                                        ;
                                        return <App accountInfo={accountInfo} login={login} logout={logout} />
                                    }
                                }
                                return <Grid container alignItems="center" direction="column" style={{padding: '20px'}}>
                                        <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                                            <h2>Only agent managers are allowed to access this app!</h2>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="secondary" onClick={logout}>Login Using Different Account</Button>                                      
                                        </Grid>
                                    </Grid>

                            case AuthenticationState.Unauthenticated:                                
                                return <Grid container alignItems="center" direction="column" style={{padding: '20px'}}>
                                        <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                                            <h2>We couldn't find your account info, please try logging in again!</h2>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="secondary" onClick={login}>Login</Button>                                      
                                        </Grid>
                                    </Grid>

                            case AuthenticationState.InProgress:
                                ;
                                return <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} />
                            
                            default:
                                ;
                                return null;
                        }
                    }
                }
            </AzureAD>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
