import React, {useState, useEffect} from 'react'
import FormWizard from "./../../../components/FormWizard/FormWizard";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import ConfirmationPage from "./ConfirmationPage";
import {
    Grid
} from '@material-ui/core';
import {saveApplication} from "../../../apiCalls/application/saveApplication";
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import {submitApplication} from "../../../apiCalls/application/submitApplication";
import Notification from "../../../components/Notification/Notification";
import {connect} from 'react-redux';
import calculateEffectiveDate from '../../../helperFunctions/effectiveDate'

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const DEFAULT_STEPS = [
    {
        label: 'Personal Information',
        component: <StepOne/>,
        data: {
            applicationId: {
                required: false,
                value: ''
            },
            memberId: {
                required: false,
                value: ''
            },
            firstName: {
                required: true,
                value: ''
            },
            middleName: {
                required: false,
                value: ''
            },
            lastName: {
                required: true,
                value: ''
            },
            dateOfBirth: {
                required: true,
                value: null
            },
            gender: {
                required: true,
                value: ''
            },
            address: {
                required: true,
                value: null
            },
            primaryPhone: {
                required: true,
                value: ''
            },
            emergencyPhone: {
                required: true,
                value: ''
            },
            email: {
                required: true,
                value: ''
            },
            center: {
                required: true,
                value: ''
            },
            dependents: {
                required: false,
                value: []
            },
            agentCode: {
                required: true,
                value: ""
            }
        }
    },
    {
        label: 'Plan Information',
        component: <StepTwo/>,
        data: {
            planType: {
                required: true,
                value: null
            },
            membershipType: {
                required: true,
                value: 'I'
            },
            oneTimeFee: {
                required: false,
                value: ''
            },
            monthlyFee: {
                required: false,
                value: ''
            },
            monthsPay: {
                required: false,
                value: ''
            },
            totalPayment: {
                required: false,
                value: ''
            },
            membershipEffectiveDate: {
                required: true,
                value: calculateEffectiveDate()
            },
            discountCode: {
                required: false,
                value: ''
            }
        }
    },
    {
        label: 'Payment',
        component: <StepThree/>,
        data: {
            paymentType: {
                required: true,
                value: 'C'
            },
            nameOnTheCard: {
                required: true,
                value: ''
            },
            cardNumber: {
                required: true,
                value: ''
            },
            cardExpiryDate: {
                required: true,
                value: null
            },
            cardCVVNumber: {
                required: true,
                value: ''
            },
            enableAutomaticPayments: {
                required: false,
                value: false
            },
            dayOfAutomaticPayment: {
                required: false,
                value: ''
            }
        }
    }
];

const NewApplication = (props) => {

    const {accountInfo} = props;

    const [steps, setSteps] = useState([...DEFAULT_STEPS]);
    const [confirmationData, setConfirmationData] = useState(null);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [allowAnotherSubmit, setAllowAnotherSubmit] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const location = useLocation();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(location && location.state && location.state.formData) {
            setSteps(prevSteps => {
                return prevSteps.map((prevStep, index) => {
                    const newData = {}
                    for(var key in location.state.formData[index]) {
                        newData[key] = {
                            required: prevStep?.data?.[key]?.required,
                            value: location.state.formData[index][key]
                        }
                    }
                    return {
                        ...prevStep,
                        data: {
                            ...prevStep.data,
                            ...newData
                            
                        }
                    }
                })
            });
        }
    }, [location.key])

    const preparePayload = form => {
        const payload = {
            addressLine1: form.address.value.addressLine1,
            addressLine2: form.address.value.addressLine2,
            addressCity: form.address.value.city,
            addressState: form.address.value.state,
            addressZip: form.address.value.zipCode,
            eMail: form.email,
            primaryPhoneNumber: form.primaryPhone,
            emergencyPhoneNumber: form.emergencyPhone,
            agentCode: form.agentCode,
            primaryCenterId: form.center,
            planInfoId: form.planType?.planInfoId,
            applicationId: form.applicationId,
            discountCode: form.discountCode,
            automaticPaymentDate: isNaN(parseInt(form.dayOfAutomaticPayment)) ? undefined : parseInt(form.dayOfAutomaticPayment),
            automaticCharge: form.enableAutomaticPayments
        }

        const members = [{
            firstName: form.firstName,
            lastName: form.lastName,
            dateOfBirth: form.dateOfBirth,
            phoneNumber: form.primaryPhone,
            genderId: form.gender,
            relationshipTypeId: 'P',
            applicationId: form.applicationId,
            memberId: form.memberId
        }]

        if(form.dependents && form.dependents.length) {
            for(let i = 0; i < form.dependents.length; i++) {
                members.push({
                    firstName: form.dependents[i].firstName,
                    lastName: form.dependents[i].lastName,
                    dateOfBirth: form.dependents[i].dateOfBirth,
                    phoneNumber: form.dependents[i].phone,
                    genderId: form.dependents[i].gender,
                    relationshipTypeId: form.dependents[i].relationship.relationshipTypeId,
                    applicationId: form.applicationId,
                    memberId: form.dependents[i].memberId || undefined
                })
            }
        }

        payload.members = members;

        if(form.membershipEffectiveDate) payload.startDate = form.membershipEffectiveDate;

        return payload;
    }
    
    const handleNextClick = form => {
        return new Promise((resolve, reject) => {
            saveApplication(axios.CancelToken.source(), preparePayload(form), authToken)
            .then(response => resolve(response))
            .catch(error => reject(error))
        });
    }

    const handleFormSubmit = form => {        
        const formWithPaymentDetails = {...preparePayload(form)};
        formWithPaymentDetails.initialAmount = form.totalPayment;
        if(form.paymentType === 'C') {
            formWithPaymentDetails.paymentMethod = {
                paymentTypeId: "card",
                cardNumber: form.cardNumber,
                cardOwnerName: form.nameOnTheCard,
                cardExpirationMonth: moment(form.cardExpiryDate).format('M'),
                cardExpirationYear: moment(form.cardExpiryDate).format('YYYY'),
                cvcCode: form.cardCVVNumber
            }
            formWithPaymentDetails.dateOfAutomaticPayment = form.dayOfAutomaticPayment;
        }
        else {
            formWithPaymentDetails.paymentMethod = {
                paymentTypeId: form.paymentType
            }
        }
        
        submitApplication(axios.CancelToken.source(), formWithPaymentDetails, authToken)
        .then(response => {
            if(response && response.success && response.application) {
                setConfirmationData({
                    membershipId: response.application.membershipId
                });
            }
            else {
                setNotification({
                    open: true,
                    type: "error",
                    message: response && response.error && response.error.message ? response.error.message :  `Failed to submit application, please try again.`
                });
                setAllowAnotherSubmit(true);
            }
        })
        .catch(error => {
            const response = error.response.data;
            console.log('Failed to submit application', error)
            setNotification({
                open: true,
                type: "error",
                message: response && response.error && response.error.message ? response.error.message :  `Failed to submit application, please try again.`
            });
            setAllowAnotherSubmit(true);
        });
        
    }
    return <Grid container>
        <Grid item xs={12}>
            {
                confirmationData ?
                <ConfirmationPage data={confirmationData} /> :
                <FormWizard 
                    steps={steps}
                    onSubmit={handleFormSubmit}
                    handleNextClick={handleNextClick}
                    allowAnotherSubmit={allowAnotherSubmit}
                    resetAllowAnotherSubmit={() => setAllowAnotherSubmit(false)}
                />                
            }
        </Grid>
        <Notification
            open={notification.open}
            type={notification.type}
            message={notification.message}
            onClose={() => {
                setNotification({...DEFAULT_NOTIFICATION})
            }}
        />
    </Grid>
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(NewApplication)