import {useState, useEffect, useCallback} from 'react';
import Table from "../../components/Table/Table";
import CommissionRowTemplate from "./CommissionRowTemplate";
import {
    Grid,
    Typography
} from '@material-ui/core';
import CommissionFilters from "./CommissionFilters";
import {getCommissionList} from "../../apiCalls/getCommissionList";
import {debounce} from 'lodash';
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import {connect} from 'react-redux';
import ExcelExporter from '../../components/ExcelExporter/ExcelExporter';

const DEFAULT_COMMISSION_LIST_HEADCELLS = [
    {id: 'commissionType', label: 'Type of Commission', showColumn: true, sortable: 'true'},
    {id: 'applicationId', label: 'Application Number', showColumn: true, sortable: 'true'},
    {id: 'commissionDate', label: 'Commission Date', showColumn: true, sortable: 'true'},
    {id: 'memberName', label: 'Member Name', showColumn: true, sortable: 'true'},
    {id: 'commissionAmount', label: 'Commission Amount', showColumn: true, sortable: 'true'},
    {id: 'status', label: 'Status', showColumn: true, sortable: 'true'}
]

const DEFAULT_COMMISSIONS_PER_PAGE = 50;

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const CommissionsList = (props) => {

    const {accountInfo} = props;

    const [refreshList, setRefreshList] = useState(false);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilterValues] = useState({});
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");
    const [totalRecords, setTotalRecords] = useState([]);

    const debounceSetPageNumber = useCallback(debounce(() => setPageNumber(0), 1000), []);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(authToken) {
            getCommissionList(source, 0, 10000, {}, authToken)
            .then((response) => setTotalRecords(response?.data))
            .catch((err) => setTotalRecords([]));
        }
    }, [authToken])

    useEffect(() => {
        if(pageNumber === 0) {
            return setPageNumber(1);
        }

        if(authToken) {
            fetchCommissionList(authToken);
        }

        return () => source.cancel();
    }, [pageNumber, accountInfo, authToken]);

    const loadMoreRecords = () => {
        setLoading(true);
        if(pageNumber < totalPages) {
            setPageNumber(prevPageNumber => prevPageNumber+1);
        }
    }

    const fetchCommissionList = (aT) => {
        setLoading(true);
        getCommissionList(source, ((pageNumber-1) * DEFAULT_COMMISSIONS_PER_PAGE),  DEFAULT_COMMISSIONS_PER_PAGE, 
        {
            ...filterValues
        }, aT)
        .then(response => {
            if(pageNumber === 1) {
                setData(response.data);
                const absolutePages = Number(response.count) / DEFAULT_COMMISSIONS_PER_PAGE;
                setTotalPages( absolutePages === Math.floor(absolutePages) ? absolutePages : Math.floor(absolutePages) + 1 );
            }
            else {
                setData(prevData => {
                    return [
                        ...data,
                        ...response.data
                    ]
                })
            };
            setLoading(false);
            setRefreshList(true);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled commission list fetching");
            } else {
                console.log('Error fetching commision list', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch commissions, please try again"
                });
            }
        });
    }

    const handleFilterChange = newFilterValues => {
        setFilterValues(prevValues => {
            return {
                ...prevValues,
                memberName: newFilterValues.memberName || undefined,
                isProcessed: newFilterValues.status === 'Processed' ? true : 
                            (newFilterValues.status === 'Draft' ? false : undefined),
                commissionType: newFilterValues.commissionType || undefined,
                filterStartDate: newFilterValues.filterStartDate || undefined,
                filterEndDate: newFilterValues.filterEndDate || undefined,
                agentCode: newFilterValues.agentCode || undefined
            }
        });
        debounceSetPageNumber();
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5">Commissions</Typography>
                    <ExcelExporter fileName={"Commission"} sheetName={"Commission"}
                        columns = {DEFAULT_COMMISSION_LIST_HEADCELLS}
                        data={totalRecords}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CommissionFilters
                        onFilterChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Table 
                        headCells={[...DEFAULT_COMMISSION_LIST_HEADCELLS]}
                        rows={data}
                        page={pageNumber}
                        totalPages={totalPages}
                        loading={loading}
                        rowTemplate={<CommissionRowTemplate/>}
                        loadMoreRecords={loadMoreRecords}
                        identifier="commissionHistoryId"
                        handleSingleClickRow={() => console.log('Single Click On Row')}
                        handleDoubleClickRow={() => console.log('Double Click On Row')}
                        tableHeight={70}
                        handleRowsOrderChange={() => console.log('Rows Order Changed')}
                        setRefreshTable={setRefreshList}
                        refreshTable={refreshList}
                        sortBy="applicationNumber"
                        sortOrder="asc"
                    />
                </Grid>
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(CommissionsList);