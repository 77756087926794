import {MsalAuthProvider, LoginType} from 'react-aad-msal';
import environment from "../config/environment";

const tenantId = "6af0fad1-5ad5-4447-82b9-3e6f1e5fa135";
const clientId = "8081ae57-0e02-4d58-8ec3-764ae72d1c01";

const config = {
    auth: {
        authority: `https://login.microsoftonline.com/${tenantId}`,
        clientId: clientId,
        postLogoutRedirectUri: environment().azureADRedirectURI,
        redirectUri: environment().azureADRedirectURI,
        validateAuthority: true,
        navigateToLoginRequestUrl: true        
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};
const authenticationParameters = {
    scopes: [
        `${clientId}/.default`
    ]
}

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)