const commissionTypes = [
    {
        label: 'Commission',
        value: 'Commission'
    },
    {
        label: 'Residual',
        value: 'Residual'
    },
    {
        label: 'Refound',
        value: 'Refound'
    }
]

export default commissionTypes;