import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const getApplicationList = (source, index, pageSize, filters, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}membership/filter`,
            {
                offset: index,
                take: pageSize, 
                ...filters
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data && response.data.data && response.data.data.length) {
                        resolve(response.data);
                    }
                    else {
                        resolve({
                            data: [],
                            count: 0
                        });
                    }
                }
                reject(response);
            })
            .catch(error => reject(error))
    })
}