import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const getAgentPerformance = (source, filters, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}performance`,
            {...filters},
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data && response.data.length) {
                        resolve(response.data[0]);
                    }
                    else resolve({
                        actual: 0,
                        salesTarget: 0,
                        distribution: [
                            {value: 0, label: 'None'}
                        ]
                    });                    
                }
            })
            .catch(error => reject(error))
    })
}

